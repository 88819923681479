import React from 'react';

import emojisJSON from './emojis.json'

const replaceWithImage = (message) => {
  let result = message;

  emojisJSON.forEach((emoji) => {
    const regex = new RegExp(emoji.trigger, 'g');
    result = result.replaceAll(regex, `<img src="${process.env.REACT_APP_BASE_PATH}${process.env.REACT_APP_HOMEPAGE}${emoji.path}"/>`);
  });
  return result;
};

const EmojiRenderer = ({ text }) => {

  const textWithEmojis = replaceWithImage(text);

  return <span className="message" dangerouslySetInnerHTML={{ __html: textWithEmojis }} />;
};

export default EmojiRenderer;
