class TokenClient {
  constructor(baseUrl, setToken) {
    this.baseUrl = baseUrl;
    this.setToken = setToken;
    this.token = null;
  }

  startTokenRenewal() {
    this.renewalInterval = setInterval(() => {
      this.setupTokenRenewal();
    }, 15 * 60 * 1000);
  }

  stopTokenRenewal() {
    clearInterval(this.renewalInterval);
  }

  async setupTokenRenewal() {
    if (this.token) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/refresh_token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`,
          },
          body: JSON.stringify({}),
        });

        if (response.ok) {
          const data = await response.json();
          this.token = data.token;
          this.setToken(this.token);
        } else {
          console.error('Failed to renew token');
        }
      } catch (error) {
        console.error('Error renewing token:', error.message);
      }
    } else {
      console.warn('No token found to renew');
    }
  }

  async getToken() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/get_token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Failed to get token');
      }

      const data = await response.json();
      this.token = data.token;
      this.startTokenRenewal(); // Start token renewal after receiving the token
      return true;
    } catch (error) {
      console.error('Error getting token:', error.message);
      return false;
    }
  }

  getTokenValue() {
    return this.token;
  }
}

export default TokenClient;
